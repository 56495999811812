// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
//import { gtag } from 'ga-gtag';
import ReactGA from 'react-ga4';

// Import Components
import SelectPlan from '../Fragments/Subscribe/SelectPlan';
import ConfirmCancelSubscription from '../Fragments/Subscribe/confirmCancel';
import ProcessSubCharge from '../Fragments/Payments/ProcessSubCharge.js';
import GeneralModal from './GeneralModal.js';

// Import Services
import subscriptionService from '../../services/subscriptionService';
import paymentService from '../../services/paymentService';

// Context
import { UserContext } from '../../App';

// Setup Modal to Root
Modal.setAppElement('#root');

const UpgradeMembership = ({
  featureMsg,
  isOpen,
  onRequestClose,
  includeFree,
  userDetails,
  currentSubscription,
  scriptId,
}) => {
  const { user, setUser } = useContext(UserContext);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [step, setStep] = useState(1);
  const [generalModalDetails, setGeneralModalDetails] = useState({
    open: false,
    header: '',
    message: '',
  });

  let useUser = userDetails ? userDetails : user;

  let dueDate = new Date();
  dueDate.setMonth(dueDate.getMonth() + 1);

  const moveToStep = async (step, plan) => {
    if (plan) {
      setSelectedPlan(plan);
    }
    if (step === 2) {
      //gtag.event('begin_checkout');
      ReactGA.event({
        category: 'Ecommerce',
        action: 'Initiated Checkout',
        value: plan.details.price,
      });
    }
    setStep(step);
    if (step === 4) {
      ReactGA.event({
        category: 'Ecommerce',
        action: 'Completed Purchase',
        label: `Order Completed`,
        value: selectedPlan.details.price,
      });
      isOpen = false;
      openConfirmModal();
    }
  };

  const openConfirmModal = () => {
    setGeneralModalDetails({
      open: true,
      header: <span>Congratulations!</span>,
      message: (
        <div>
          You successfully activated your subscription. You can now access all of our advanced
          features. This includes
          <ul>
            <li>Generate shots for every scene</li>
            <li>Generate shots for the entire script in one click (coming soon)</li>
            <li>More Fields: Multi-Cam, etc.</li>
            <li>Flexible Export Options including CSV</li>
            <li>Upload Images and include them in PDF Exports</li>
          </ul>
          <br />
          <br />
          {scriptId ? (
            <div>This script has been upgrade to a full featured script as well.</div>
          ) : null}
          <div className="fullWidthContainer">
            <span className="OneHalfFlex"></span>
            <button
              className="buttonGray OneHalfFlex"
              onClick={() => setGeneralModalDetails(false)}
            >
              Get Started
            </button>
          </div>
        </div>
      ),
    });
  };

  useEffect(() => {
    if (generalModalDetails === false) {
      window.location.reload();
    }
  }, [generalModalDetails]);

  useEffect(() => {
    setGeneralModalDetails({ open: false, header: null, message: null });
    //gtag.event('view_item');
    ReactGA.event({
      category: 'Ecommerce',
      action: 'Viewed Product',
      label: 'Subscription Modal',
    });
    setStep(1);
  }, [isOpen]);

  return (
    <>
      {step < 4 ? (
        <Modal isOpen={isOpen} contentLabel="Idle Modal" className="modal wide">
          <div className="close-button" role="button" onClick={() => onRequestClose(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div>
            {step === 1 ? (
              <SelectPlan
                featureMsg={featureMsg}
                includeFree={includeFree}
                nextStep={moveToStep}
                currentSubscription={currentSubscription}
                scriptId={scriptId}
              />
            ) : step === 2 ? (
              <ProcessSubCharge
                plan={selectedPlan}
                onRequestClose={onRequestClose}
                scriptId={scriptId}
                nextStep={moveToStep}
              />
            ) : step === 3 ? (
              <ConfirmCancelSubscription
                currentSubscription={currentSubscription}
                onRequestClose={onRequestClose}
              />
            ) : null}
          </div>
          <div className="FullWidthFlex" style={{ paddingTop: '30px', marginBottom: '-20px' }}>
            <span className="FivePortionFlex">&nbsp;</span>
            <span
              className="OnePortionFlex buttonClone"
              style={{ height: '5px', backgroundColor: step === 1 ? '#a899a8' : '' }}
              onClick={() => moveToStep(1)}
            ></span>
            <span
              className="OnePortionFlex buttonClone"
              style={{ height: '5px', backgroundColor: step === 2 ? '#a899a8' : '' }}
            ></span>
            <span className="FivePortionFlex">&nbsp;</span>
          </div>
        </Modal>
      ) : (
        <GeneralModal
          generalModalDetails={generalModalDetails}
          setModalIsOpen={setGeneralModalDetails}
        />
      )}
    </>
  );
};

export default UpgradeMembership;
