import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//import gtag from 'ga-gtag';
import ReactGA from 'react-ga4';

const TrackPageViews = ({ user }) => {
  const location = useLocation();

  useEffect(() => {
    /*gtag.config('G-MJJZM9T77T', {
      page_path: location.pathname,
    });*/

    ReactGA.send({ hitType: 'pageview', page: location.pathname, user_id: user.id });
  }, [location, user]);

  return null;
};

export default TrackPageViews;
