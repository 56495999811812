// Libraries
import React, { useState, useContext, useRef, useEffect } from 'react';

// Components
import ProFlag from '../Elements/ProFlag.js';
import ImageUpload from '../Elements/ShotCard/ImageUpload.js';
import CoreForm from '../Elements/ShotCard/CoreForm.js';
import ExpandedForm from '../Elements/ShotCard/ExpandedForm.js';
import ControlBox from '../Elements/ShotCard/ControlBox.js';
import GeneralModal from '../Modals/GeneralModal.js';

// Context
import { UserContext } from '../../App.js';
import ScriptContext from '../../context/Script';
import SessionContext from '../../context/Session';
import ProjectViewContext from '../../context/ProjectView';

// Services
import dictionaryService from '../../services/dictionaryService.js';
import scriptService from '../../services/scriptService.js';
import shotService from '../../services/shotService.js';
import ProjectSockets from '../../services/projectSockets';

// Construct Component
const Shot = ({
  shot,
  isSelected,
  updateShot,
  scene,
  dictionaries,
  updateDictionaries,
  deleteHandler,
  addShot,
}) => {
  const { user } = useContext(UserContext);
  const { script } = useContext(ScriptContext);
  const { isMobile, generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);
  const { view } = useContext(ProjectViewContext);
  const componentRef = useRef(null);

  const expandContractShot = () => {
    shot.expanded = !shot.expanded;
    updateShot(scene.id, shot, 'expanded');
  };

  const addNewUserDictionary = async (value, dictionary) => {
    value = value && value.value ? value.value : value;
    const newEntry = await dictionaryService.addUserDictionaryEntry(
      user.id,
      dictionary,
      value,
      shot.id
    );
    if (newEntry) {
      dictionaries[dictionary].push(newEntry);
      updateDictionaries(dictionaries);
      //let result = shot[dictionary] ? shot[dictionary].push(newEntry.value) : [newEntry.value];
      //handleShotFieldChange(shot.id, dictionary, result);
      return newEntry;
    }
  };

  const handleShotFieldChange = async (shotId, field, update) => {
    try {
      let workingShot = shot;
      let fullReplace = false;
      if (shotId === 0) {
        workingShot = await shotService.addShot(scene.id, 1);
        shotId = workingShot.id;
        shot.id = shotId;
        fullReplace = true;
      } else if (!shotId) {
        shotId = shot.id;
      }

      let sanitizedUpdate = update;
      // If it is a dictionary update, strip out the label and only send the value
      if (update && update[0] && update[0].value) {
        sanitizedUpdate = update ? update.map((item) => item.value) : [];
        let userDictionaries = ['movement', 'camera', 'focus', 'frame', 'focal'];
        if (userDictionaries.includes(field)) {
          update = sanitizedUpdate;
        }
      }
      let response = await scriptService.changeShotChange(shotId, field, sanitizedUpdate, scene.id);

      workingShot[field] = update;

      if (field === 'subject') {
        workingShot[field] = update.map((item) => ({
          subject_id: item,
        }));
      }
      if (!fullReplace) {
        updateShot(scene.id, workingShot, field);
      } else {
        updateShot(scene.id, workingShot, 'full', 0);
      }
      return;
    } catch (error) {
      console.error(error);
    }

    window.scrollTo(0, window.scrollY);
  };

  const uploadAttachment = async (file) => {
    let attachment = await shotService.uploadAttachment(script.script_path, shot.id, file);
    if (attachment.error) {
      setGeneralModalDetails({
        open: true,
        header: <span>Uh oh!</span>,
        message: (
          <div>
            We encountered an error uploading this attachment: {attachment.error}
            <br />
            <br />
            Please try a new image, ensuring it is 2MB or less and is a valid image file or{' '}
            <a href="https://www.shotkraft.com/help" target="_blank">
              Contact Us
            </a>{' '}
            .
            <br />
            <br />
            <div className="fullWidthContainer">
              <span className="OneHalfFlex"></span>
              <button
                className="buttonGray OneHalfFlex"
                onClick={() => setGeneralModalDetails({ open: false })}
              >
                Cancel
              </button>
            </div>
          </div>
        ),
      });
    } else if (attachment) {
      shot.image = attachment;
      updateShot(scene.id, shot, 'image');
    }
  };

  const retrieveImg = async () => {
    shot.image = await shotService.retrieveImg(shot.id);
    updateShot(scene.id, shot, 'image');
  };

  const deleteImg = async () => {
    let result = await shotService.deleteShotImgs(shot.id);
    if (result) {
      shot.image = null;
      updateShot(scene.id, shot, 'image');
    } else {
      setGeneralModalDetails({
        open: true,
        header: <span>Uh oh!</span>,
        message: (
          <div>
            We encountered an error deleting this attachment. Please try again or{' '}
            <a href="https://www.shotkraft.com/help" target="_blank">
              Contact Us
            </a>{' '}
            .
            <br />
            <br />
            <div className="fullWidthContainer">
              <span className="OneHalfFlex"></span>
              <button
                className="buttonGray OneHalfFlex"
                onClick={() => setGeneralModalDetails({ open: false })}
              >
                Cancel
              </button>
            </div>
          </div>
        ),
      });
    }
  };

  useEffect(() => {
    if (view.includeImages && shot.attachments && shot.attachments.length > 0) {
      retrieveImg();
    }
  }, [shot.attachments, view.includeImages]);

  return (
    <div className={`fullWidthContainer`}>
      {generalModalDetails && (
        <GeneralModal
          generalModalDetails={generalModalDetails}
          setModalIsOpen={setGeneralModalDetails}
        />
      )}
      {!isMobile ? (
        <div
          className="OnePortionFlex"
          style={{
            fontWeight: '600',
            alignSelf: 'flex-start',
            paddingTop: '10px',
          }}
        >
          {scene.scene_number ? <>{scene.scene_number}.</> : null}
          {`${shot.shot_number}`}
        </div>
      ) : null}
      <div
        className={`card flatLeftCard ${isSelected ? 'selectedShot' : 'flatLeftCardBorder'}`}
        style={{
          backgroundColor: '#F9FAFC',
          boxShadow: 'none',
          flex: '12',
        }}
      >
        <div className="FullWidthFlex">
          {view.includeImages ? (
            <span
              className={`shotImageWide ${
                view.viewColumns === 2 || isMobile
                  ? 'FullWidthFlex Padded MinSmall'
                  : 'OnePortionFlex Padded'
              } `}
              style={{ alignSelf: 'center', textAlign: 'center' }}
            >
              {view.sceneImagesAllowed ? (
                <ImageUpload
                  uploadAttachment={uploadAttachment}
                  deleteAttachment={deleteImg}
                  image={shot && shot.image ? shot.image : null}
                  readOnly={script.level === 'viewer' ? true : false}
                />
              ) : (
                <span
                  className="OnePortionFlex Padded"
                  style={{
                    alignSelf: 'center',
                    textAlign: 'center',
                    height: '30px',
                    width: '30px',
                    border: '1px solid #ccc',
                  }}
                >
                  <ProFlag
                    type="pro"
                    scriptLevel={script ? script.features : 'Basic'}
                    scriptId={script.id}
                  />
                  Image
                </span>
              )}
            </span>
          ) : null}

          {view.viewColumns === 1 ? (
            <div className={`${view.viewColumns === 1 ? 'FourPortionFlex' : ''}`}>
              <CoreForm
                scene={scene}
                shot={shot}
                dictionaries={dictionaries}
                handleShotFieldChange={handleShotFieldChange}
                updateDictionaries={updateDictionaries}
              />
              <ExpandedForm
                scene={scene}
                shot={shot}
                dictionaries={dictionaries}
                handleShotFieldChange={handleShotFieldChange}
                addNewUserDictionary={addNewUserDictionary}
              />
            </div>
          ) : (
            <>
              <CoreForm
                scene={scene}
                shot={shot}
                dictionaries={dictionaries}
                handleShotFieldChange={handleShotFieldChange}
                updateDictionaries={updateDictionaries}
              />
              <ExpandedForm
                scene={scene}
                shot={shot}
                dictionaries={dictionaries}
                handleShotFieldChange={handleShotFieldChange}
                addNewUserDictionary={addNewUserDictionary}
              />
            </>
          )}
        </div>
      </div>

      {!isMobile ? (
        <ControlBox
          scene={scene}
          shot={shot}
          deleteHandler={deleteHandler}
          addShot={addShot}
          expandContractShot={expandContractShot}
          view={view}
        />
      ) : null}
    </div>
  );
};

export default Shot;
