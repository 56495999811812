import React, { useEffect, useState, useContext } from 'react';
import ReactPixel from 'react-facebook-pixel';

// Services
import userService from '../../services/userService';
import dictionaryService from '../../services/dictionaryService';

// Components
import SelectBox from '../../components/Elements/Select';
import Icon from '../../components/Elements/Icon';

// Import Context
import { UserContext } from '../../App';
import SessionContext from '../../context/Session';

// Build Component
const NewUserWelcome = ({ userDetails }) => {
  const { user } = useContext(UserContext);
  const { visitedWelcome, setVisitedWelcome } = useContext(SessionContext);
  const [error, setError] = useState(null);
  const [aboutUsDict, setAboutUsDict] = useState([]);
  const [userData, setUserData] = useState({
    first_name: userDetails.first_name || user.first_name || '',
    last_name: userDetails.last_name || user.last_name || '',
    id: userDetails.id || user.id || '',
    about_us: '',
    about_us_text: '',
  });

  const updateUser = async () => {
    try {
      let isDataComplete = await checkMissingData();
      if (isDataComplete) {
        let data = userData;
        data.id = userDetails.id ? userDetails.id : user.id;
        let userUpdate = await userService.updateProfileFields(data);
        if (userUpdate) {
          window.location.href = `/app/freeTrialActivated`;
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const checkMissingData = async () => {
    if (!userData.first_name || !userData.last_name || !userData.about_us) {
      setError('Please make sure all required fields are filled out.');
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const updateAboutUs = async (value) => {
    setUserData({ ...userData, about_us: value });
  };

  useEffect(() => {
    if (!window.location.href.includes('demo') && !window.location.href.includes('localhost')) {
      ReactPixel.init('234261399680296');
      ReactPixel.track('CompleteRegistration', {
        email: userDetails.email,
      });
    }

    const fetchData = async () => {
      let getAboutUs = await dictionaryService.fetchDictionary('about_us');
      getAboutUs.sort((a, b) => (a.order ? a.order - b.order : 0));

      setAboutUsDict(getAboutUs);
    };

    if (userDetails || user) {
      fetchData();
    }

    setVisitedWelcome(true);
  }, []);

  useEffect(() => {
    if ((userDetails.id || user.id) && !userData.id) {
      let useUser = userDetails ? userDetails : user;
      setUserData({
        ...userData,
        id: useUser.id,
        first_name: useUser.first_name,
        last_name: useUser.last_name,
      });
    }
  }, [userDetails, user]);

  useEffect(() => {
    if (error) {
      checkMissingData();
    }
  }, [userData]);

  return (
    <div className="DeepPadded">
      <div className="large bold centered">
        Welcome to ShotKraft!
        <Icon icon="Wave" size={35} color="Yellow" />
      </div>
      <div className="fullWidthContainer">
        <p className="centered subHeader">Let's get to know you </p>
        <div style={{ width: '100%' }}>
          <div className="leftLabel topPadded">First Name</div>
          <input
            className={`addBottomSpacing ${!userData.first_name && error ? 'InputError' : ''}`}
            placeholder="i.e. Allan"
            value={userData.first_name}
            onChange={(e) => {
              setUserData({ ...userData, first_name: e.target.value });
            }}
          />

          <div className="leftLabel topPadded">Last Name</div>
          <input
            className={`addBottomSpacing ${!userData.last_name && error ? 'InputError' : ''}`}
            placeholder="i.e. Smithy"
            value={userData.last_name}
            onChange={(e) => {
              setUserData({ ...userData, last_name: e.target.value });
            }}
          />

          <div className="leftLabel topPadded">How did you hear about us</div>
          <div style={{ width: '100%' }}></div>
          <SelectBox
            selectLabel={``}
            labelStyle={`none`}
            selectOptions={aboutUsDict.map((item) => item.dict_about_us)}
            selectedValue={userData.about_us}
            handleSelectChange={updateAboutUs}
            useClass={`addBottomSpacing removeFormMargin ${
              !userData.about_us && error ? 'InputError' : ''
            }`}
          />
          <textarea
            className="fullWidthContainer addBottomSpacing"
            style={{ height: '80px' }}
            placeholder="Can you give more specifics: What was your search query on google? Which social media? Etc. (optional, but highly appreciated)"
            value={userData.about_us_text}
            onChange={(e) => {
              setUserData({ ...userData, about_us_text: e.target.value });
            }}
          />
        </div>

        <div className="fullWidthContainer">
          <p className="errorText">{error}</p>
        </div>

        <button
          style={{ height: '45px', fontSize: 'larger' }}
          className="buttonClone fullWidth"
          onClick={() => updateUser()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default NewUserWelcome;
