// Import Libraries
import React, { useEffect, useState, useContext } from 'react';

// Services
import userService from '../services/userService';
import dictionaryService from '../services/dictionaryService';

// Components
import SelectBox from '../components/Elements/Select';
import UserDetails from '../components/UserComponents/userDetails';
import AboutUs from '../components/UserComponents/aboutUs';
import UserSubscription from '../components/UserComponents/subscription';

function Profile({ user, isStaff }) {
  const [error, setError] = useState(null);
  const [aboutUsDict, setAboutUsDict] = useState([]);
  const [userData, setUserData] = useState({
    first_name: user ? user.first_name : '',
    last_name: user ? user.last_name : '',
    about_us: '',
    about_us_text: '',
  });

  /*
  const updateUser = async () => {
    try {
      let data = userData;
      data.id = user.id;
      let userUpdate = await userService.updateProfileFields(data);
    } catch (error) {
      setError(error.message);
    }
  };
  */

  /*
  const updateAboutUs = async (value) => {
    setUserData({ ...userData, about_us: value });
  };
  */

  useEffect(() => {
    const fetchData = async () => {
      let getAboutUs = await dictionaryService.fetchDictionary('about_us');
      setAboutUsDict(getAboutUs);
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  return (
    <div className="Page">
      {!isStaff ? <div className="bold large">Welcome {`${user.first_name}`}</div> : null}
      <div className="fullWidthContainer" style={{ paddingTop: '20px' }}>
        <div className="FivePortionFlex">
          <UserDetails userDetails={user} />
        </div>

        <div className="OnePortionFlex">&nbsp;</div>

        <div className="FivePortionFlex">
          <AboutUs userDetails={user} />
        </div>
      </div>
      <div className="fullWidthContainer">
        <UserSubscription userDetails={user} />
      </div>
    </div>
  );
}

export default Profile;
