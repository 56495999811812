// Import Libraries
import React, { useState, useContext } from 'react';

// Import Components
import CustomSelect from '../../Forms/CustomSelect.js';

// Services
import dictionaryService from '../../../services/dictionaryService.js';

// Context
import ScriptContext from '../../../context/Script';
import ProjectViewContext from '../../../context/ProjectView';

// Component
function CoreForm({ scene, shot, dictionaries, handleShotFieldChange, updateDictionaries }) {
  const { script } = useContext(ScriptContext);
  const [tempDescriptions, setTempDescriptions] = useState({});
  const { lockedFields, myLockedField, lockField, unlockField } = useContext(ProjectViewContext);
  const isReadOnly = script.level === 'viewer';

  const handleUpdateDescription = async (sceneNumber, shotId) => {
    if (tempDescriptions[shotId] === undefined) return;
    handleShotFieldChange(shotId, 'shot_description', tempDescriptions[shotId]);
    setTempDescriptions((prev) => ({
      ...prev,
      [shotId]: undefined,
    }));
    return;
  };

  const addNewSubject = async (value) => {
    value = value && value.value ? value.value : value;
    const newEntry = await dictionaryService.addDictionaryEntry(
      'subjects',
      value,
      'subject',
      scene.script_id
    );
    if (newEntry) {
      dictionaries['subjects'].push(newEntry);
      updateDictionaries(dictionaries);
      return newEntry;
    }
  };

  return (
    <div className="FullWidthFlex OnePortionFlex Padded MinSmall">
      <div className="OnePortionFlex Padded MinSmall">
        <CustomSelect
          optionList={dictionaries['types']
            .filter((item) => item)
            .map((item) => ({ label: item, value: item }))}
          selected={shot.shot_type1 ? [shot.shot_type1] : []}
          baseItem={shot}
          selectedFieldName={'shot_type1'}
          changeHandler={handleShotFieldChange}
          label={'Shot Type'}
          id={`shot-${shot.id}-shot_type1`}
          readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-shot_type1`)}
          lockField={lockField}
          unlockField={unlockField}
        />
      </div>
      <div className="OnePortionFlex Padded MinSmall">
        <CustomSelect
          optionList={dictionaries['subjects']}
          selected={!shot.subject ? [] : shot.subject.map((item) => item.subject_id)}
          baseItem={shot}
          selectedFieldName={'subject'}
          changeHandler={handleShotFieldChange}
          addOption={addNewSubject}
          label={'Subjects'}
          multi={true}
          id={`shot-${shot.id}-subjects`}
          readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-subjects`)}
        />
      </div>
      <div className="OnePortionFlex Padded MinSmall">
        <div className="focusLabelField">
          <span className="focusLabel">
            <span className="focusLabelText">Description</span>
          </span>
          <textarea
            className={`inputWithLabel ${
              isReadOnly || lockedFields.has(`shot-${shot.id}-description`) ? 'readOnly' : ''
            }`}
            value={
              tempDescriptions[shot.id] !== undefined
                ? tempDescriptions[shot.id]
                : shot.shot_description || ''
            }
            onChange={(e) =>
              setTempDescriptions((prev) => ({
                ...prev,
                [shot.id]: e.target.value,
              }))
            }
            onBlur={() => {
              handleUpdateDescription(scene.id, shot.id);
            }}
            id={`shot-${shot.id}-description`}
            readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-description`)}
          />
        </div>
      </div>
    </div>
  );
}

export default CoreForm;
