// Import Libraries
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

// Services
import AdminSubSvc from '../../services/adminSubscriptionService';
import AdminUtils from '../../services/utils';
import subscriptionSvc from '../../../services/subscriptionService';

// Import Components
import AddCreditsModal from '../subscriptions/addCreditsModal';
import SubtractCreditsModal from '../subscriptions/subtractCredits';

const UserCredits = ({ openUser, subscription, level }) => {
  const [error, setError] = useState(null);
  const [credits, setCredits] = useState([]);
  const [baseInvoiceUrl, setbaseInvoiceUrl] = useState();
  const [isCreditModalOpen, setCreditModalIsOpen] = useState(false);
  const [isCreditSubtractModalOpen, setCreditSubtractModalIsOpen] = useState(false);
  const [showCreditUsage, setShowCreditUsage] = useState(null);

  const showUsage = (id) => {
    if (id === showCreditUsage) {
      setShowCreditUsage(null);
    } else {
      setShowCreditUsage(id);
    }
  };

  const retrieveCredits = async () => {
    try {
      openUser.available_credits = await subscriptionSvc.fetchAvailableCredits(openUser.id);
      let getCredits = {};
      if (subscription && subscription.id) {
        getCredits = await AdminSubSvc.getAllCreditUsage(subscription.id, null);
      } else if (openUser && openUser.id) {
        getCredits = await AdminSubSvc.getAllCreditUsage(null, openUser.id);
      }
      setCredits(getCredits);
    } catch (error) {
      console.error('Error fetching credit usage:', error);
    }
  };

  useEffect(() => {
    const site = window.location.origin;
    if (site.indexOf('demo') === -1 && site.indexOf('localhost') === -1) {
      setbaseInvoiceUrl(`https://dashboard.stripe.com/invoices`);
    } else {
      setbaseInvoiceUrl(`https://dashboard.stripe.com/test/invoices`);
    }
    retrieveCredits();
  }, []);

  useEffect(() => {
    if (isCreditSubtractModalOpen === false) {
      retrieveCredits();
    }
  }, [isCreditSubtractModalOpen]);

  return (
    <>
      <AddCreditsModal
        user={openUser}
        sub={subscription}
        isModalOpen={isCreditModalOpen}
        setModalIsOpen={setCreditModalIsOpen}
      />
      <SubtractCreditsModal
        user={openUser}
        allowance={isCreditSubtractModalOpen}
        isModalOpen={isCreditSubtractModalOpen}
        setModalIsOpen={setCreditSubtractModalIsOpen}
      />
      <div className="card TenPortionFlex">
        <div className="cardHeader FullWidthFlex bold">
          <span className="TwoPortionFlex">ID</span>
          <span className="OnePortionFlex">Status</span>
          <span className="TwoPortionFlex">Available On</span>
          <span className="TwoPortionFlex">Use By</span>
          <span className="TwoPortionFlex">Invoice</span>
          <span className="OnePortionFlex">Starting Balance</span>
          <span className="OnePortionFlex">Credits Used</span>
          <span className="OnePortionFlex">Current Balance</span>
          <span className="OnePortionFlex">
            {level == 'user' ? (
              <FontAwesomeIcon
                className="linkText large"
                icon={faPlusCircle}
                onClick={() => setCreditModalIsOpen(true)}
              />
            ) : null}
          </span>
        </div>

        {credits && credits.length > 0 ? (
          <>
            {credits.map((credit) => (
              <React.Fragment key={credit.id}>
                <div className="FullWidthFlex">
                  <span
                    className="TwoPortionFlex"
                    style={{
                      paddingLeft: '5px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      minWidth: '0',
                    }}
                  >
                    {' '}
                    {credit.id}
                  </span>
                  <span className="OnePortionFlex">{credit.status}</span>
                  <span className="TwoPortionFlex">
                    {credit && credit.available_on
                      ? AdminUtils.FormatDate(credit.available_on)
                      : ''}
                  </span>
                  <span className="TwoPortionFlex">
                    {credit && credit.must_use_by ? AdminUtils.FormatDate(credit.must_use_by) : ''}
                  </span>
                  <span className="TwoPortionFlex">
                    {credit.invoice ? (
                      <a
                        className="linkText"
                        style={{ padding: '0px' }}
                        target="_blank"
                        href={`${baseInvoiceUrl}/${credit.ext_invoice_id}`}
                      >
                        {credit.invoice}
                      </a>
                    ) : (
                      'No Invoice Found'
                    )}
                  </span>
                  <span className="OnePortionFlex">{credit.allowance}</span>
                  <span className="OnePortionFlex">
                    {credit.creditsUsed > 0 ? (
                      <span
                        className="linkText"
                        style={{ padding: '0px' }}
                        onClick={() => showUsage(credit.id)}
                      >
                        {credit.creditsUsed}
                      </span>
                    ) : (
                      '0'
                    )}
                  </span>
                  <span className="OnePortionFlex">{credit.allowance - credit.creditsUsed}</span>
                  <span className="OnePortionFlex">
                    <FontAwesomeIcon
                      className="linkText orangeText"
                      icon={faMinusCircle}
                      onClick={() => setCreditSubtractModalIsOpen(credit)}
                    />
                  </span>
                </div>
                {showCreditUsage == credit.id ? (
                  <div className="Padded">
                    {credit.usage.map((use) => (
                      <React.Fragment key={use.id}>
                        <div
                          className="Padded"
                          style={{ textAlign: 'right', paddingRight: '30px' }}
                        >
                          Used {use.credits_used} credits on {AdminUtils.FormatDate(use.date)} for{' '}
                          {use.ref_type} {use.ref_id}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </>
        ) : null}
      </div>
    </>
  );
};

export default UserCredits;
