// Import Libraries
import React, { useEffect, useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Import Components
import StripeNewCard from './StripeNewCard';

// Import Services
import paymentService from '../../../services/paymentService';

// Context
import { UserContext } from '../../../App';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const WrappedStripeNewCard = ({ plan, hasCards, setNewCard, scriptId, invoice, nextStep }) => {
  const { user, setUser } = useContext(UserContext);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const setupIntent = async () => {
      if (plan || invoice) {
        try {
          const intent = await paymentService.createSetupIntent(user.id);
          if (intent) {
            setClientSecret(intent.clientSecret);
            setUser({ ...user, stripeCustomerId: intent.customerId });
          }
        } catch (error) {
          console.error('Error creating payment intent:', error);
          // Handle error appropriately (e.g., display error message to the user)
        }
      }
    };

    setupIntent();
  }, [plan, invoice]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <StripeNewCard
            plan={plan}
            hasCards={hasCards ? true : false}
            setNewCard={setNewCard}
            scriptId={scriptId}
            clientSecret={clientSecret}
            invoice={invoice}
            nextStep={nextStep}
          />
        </Elements>
      ) : null}
    </>
  );
};

export default WrappedStripeNewCard;
