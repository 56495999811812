//import gtag from 'ga-gtag';
import ReactGA from 'react-ga4';

const GA_MEASUREMENT_ID = 'G-MJJZM9T77T';

const initializeGA = (userId) => {
  /*gtag.init(GA_MEASUREMENT_ID, {
    linker: {
      domains: ['shotkraft.com', 'shotkraft.app'],
    },
    user_properties: {
      user_id: userId,
    },
  });*/
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export default initializeGA;
