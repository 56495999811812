// Import Libraries
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// Services
import userService from '../../services/userService';

const UserDetails = ({ userDetails }) => {
  const [error, setError] = useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const [userData, setUserData] = useState({
    first_name: userDetails.first_name,
    last_name: userDetails.last_name,
  });

  const updateUser = async () => {
    try {
      let data = userData;
      data.id = userDetails.id;
      let userUpdate = await userService.updateProfileFields(data);
      if (userUpdate) {
        setShowSaved(true);
        setTimeout(() => {
          setShowSaved(false);
        }, 2000);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (userDetails) {
      setUserData({
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
      });
    }
  }, [userDetails]);

  return (
    <div className="card" style={{ minWidth: '300px' }}>
      <div style={{ padding: '10px' }}>
        <div className="cardTitle fullWidthContainer">
          <span className="OnePortionFlex">User Details</span>
          <span className="OnePortionFlex"></span>
          {!showSaved && (
            <button className="OnePortionFlex buttonClone" onClick={() => updateUser()}>
              Save Profile
            </button>
          )}
          {showSaved && <FontAwesomeIcon className="greenText large" icon={faCheck} />}
        </div>
        <div className="focusLabelField fullWidthFlex">
          <span className="focusLabel onePortionFlex">
            <span className="focusLabelText">First Name</span>
          </span>
          <input
            className="inputWithLabel threePortionFlex"
            placeholder="First Name"
            value={userData.first_name}
            onChange={(e) => {
              setUserData({ ...userData, first_name: e.target.value });
            }}
          />
        </div>
        <div className="focusLabelField fullWidthFlex">
          <span className="focusLabel onePortionFlex">
            <span className="focusLabelText">Last Name</span>
          </span>
          <input
            className="inputWithLabel threePortionFlex"
            placeholder="Last Name"
            value={userData.last_name}
            onChange={(e) => {
              setUserData({ ...userData, last_name: e.target.value });
            }}
          />
        </div>
        <div className="focusLabelField fullWidthFlex">
          <input className="inputWithLabel threePortionFlex" value={userDetails.email} readOnly />
        </div>

        <div className="fullWidthContainer">
          <p className="errorText">{error}</p>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
