// Libraries
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Components
import GeneralModal from '../../components/Modals/GeneralModal.js';
import Shot from '../../components/Fragments/Shot.js';
import ProFlag from '../../components/Elements/ProFlag.js';
import ControlBox from '../../components/Elements/ShotCard/ControlBox.js';
import Icon from '../../components/Elements/Icon.js';

// Context
import { UserContext } from '../../App';
import ScriptContext from '../../context/Script';
import SessionContext from '../../context/Session';
import ProjectViewContext from '../../context/ProjectView';

// Services
import dictionaryService from '../../services/dictionaryService';
import shotService from '../../services/shotService';
import scriptService from '../../services/scriptService';

// Construct Component
const ShotTable = () => {
  const { user } = useContext(UserContext);
  const { script, setScript, updateScriptScene } = useContext(ScriptContext);
  const { isMobile, generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);
  const { view, setView } = useContext(ProjectViewContext);
  const [selectedShot, setSelectedShot] = useState(null);
  const [loadComplete, setLoadComplete] = useState(false);
  const [shotDictionaries, setShotDictionaries] = useState({
    frame: [],
    focus: [],
    camera: [],
    movement: [],
    focal: [],
    subjects: [],
    types: [],
  });

  const isDraggable = script.level !== 'viewer';

  const deleteHandler = (sceneNumber, id) => {
    setGeneralModalDetails({
      open: true,
      header: (
        <>
          <span style={{ paddingRight: '20px', color: '#E35640' }}>
            <Icon
              icon="Trash"
              color="Orange"
              customStyle={{ width: '20px', marginBottom: '-6px' }}
            />
          </span>
          <span>Delete Shot</span>
        </>
      ),
      message: (
        <div>
          Are you sure you want to delete this shot? This action cannot be undone.
          <br />
          <br />
          <div className="fullWidthContainer">
            <button
              className="buttonGray OneHalfFlex"
              onClick={() =>
                setGeneralModalDetails({
                  open: false,
                  header: null,
                  message: null,
                })
              }
            >
              Cancel
            </button>
            <button
              className="OneHalfFlex"
              style={{ backgroundColor: '#E35640', color: '#fff' }}
              onClick={() => confirmedDeleteHandler(sceneNumber, id)}
            >
              Delete Shot
            </button>
          </div>
        </div>
      ),
    });
  };

  const addShot = async (shotId, sceneIndex = view.sceneIndex) => {
    try {
      const currentScene = script.scenes[sceneIndex];
      const shotNumber = shotId
        ? currentScene.shot_list.find((s) => s.id === shotId).shot_number + 1
        : 1;
      const newShot = await shotService.addShot(currentScene.id, shotNumber);

      let newScene = {};

      const updatedScenes = [...script.scenes];
      const updatedShotList = [...currentScene.shot_list];
      if (shotId) {
        const shotIndex = updatedShotList.findIndex((s) => s.id === shotId) + 1;
        updatedShotList.splice(shotIndex, 0, newShot);
      } else {
        updatedShotList.push(newShot);
      }
      updatedScenes[sceneIndex] = { ...currentScene, shot_list: updatedShotList };
      newScene = { ...currentScene, shot_list: updatedShotList };
      let updatedScript = { ...script, scenes: updatedScenes };

      setSelectedShot(newShot.id);
      await updateShotNumbers(newScene, updatedScript);
    } catch (error) {
      console.error('Error adding shot:', error);
    }
  };

  const handleShotFieldChange = async (sceneNumber, shotId, field, update, updatedScript) => {
    try {
      const useBaseScript = updatedScript ? updatedScript : script;
      const sceneIndex = useBaseScript.scenes.findIndex((scene) => scene.id === sceneNumber);
      if (sceneIndex === -1) return;

      const updatedScene = { ...useBaseScript.scenes[sceneIndex] };
      const shotIndex = updatedScene.shot_list.findIndex((s) => s.id === shotId);
      if (shotIndex === -1) return;

      await scriptService.changeShotChange(shotId, field, update, sceneNumber);
      if (field === 'deleted_at') {
        updatedScene.shot_list.splice(shotIndex, 1);
      } else {
        updatedScene.shot_list[shotIndex] = {
          ...updatedScene.shot_list[shotIndex],
          [field]: update,
        };
      }
      const updatedScenes = [...useBaseScript.scenes];
      updatedScenes[sceneIndex] = updatedScene;

      if (updatedScript) {
        return { ...useBaseScript, scenes: updatedScenes };
      } else {
        setScript((prevScript) => {
          return { ...prevScript, scenes: updatedScenes };
        });

        if (field === 'deleted_at') {
          await updateShotNumbers(updatedScene);
        }
      }
    } catch (error) {
      console.error('Error handling shot field change:', error);
    }
  };

  const updateShotNumbers = async (scene, updatedScript) => {
    let shotList = scene.shot_list;
    for (let i = 0; i < shotList.length; i++) {
      let shotNum = i + 1;
      if (scene.shot_list[i].shot_number !== shotNum) {
        updatedScript = await handleShotFieldChange(
          scene.id,
          scene.shot_list[i].id,
          'shot_number',
          shotNum,
          updatedScript
        );
      }
    }
    if (updatedScript) {
      setScript(updatedScript);
    }
  };

  const updateShot = async (
    sceneId,
    updatedShot,
    updatedField = 'full',
    previousId = updatedShot.id
  ) => {
    try {
      const sceneIndex = script.scenes.findIndex((scene) => scene.id === sceneId);
      if (sceneIndex === -1) return false;

      const updatedScene = { ...script.scenes[sceneIndex] };
      const shotIndex = updatedScene.shot_list.findIndex((s) => s.id === previousId);
      if (shotIndex === -1) return false;

      if (updatedField === 'full') {
        updatedScene.shot_list[shotIndex] = updatedShot;
      } else {
        updatedScene.shot_list[shotIndex] = {
          ...updatedScene.shot_list[shotIndex],
          [updatedField]: updatedShot[updatedField],
        };
      }

      setScript((prevScript) => {
        const updatedScenes = [...prevScript.scenes];
        updatedScenes[sceneIndex] = updatedScene;
        return { ...prevScript, scenes: updatedScenes };
      });

      return true;
    } catch (error) {
      console.error('Error updating shot:', error);
      return false;
    }
  };

  const confirmedDeleteHandler = async (sceneNumber, id) => {
    setGeneralModalDetails({ open: false, header: null, message: null });
    await handleShotFieldChange(
      sceneNumber,
      id,
      'deleted_at',
      moment().format('YYYY-MM-DD HH:mm:ss')
    );
  };

  function includeImages(include) {
    if (view.sceneIndex === null) return;
    if (
      !script.scenes[view.sceneIndex].shot_list ||
      script.scenes[view.sceneIndex].shot_list.length === 0
    )
      return;

    view.includeImages = include;
    setView((prevView) => {
      return {
        ...prevView,
        includeImages: include,
      };
    });
  }

  function expandAll(expand) {
    if (view.sceneIndex === null) return;
    const currentScene = script.scenes[view.sceneIndex];
    if (!currentScene || !currentScene.shot_list || currentScene.shot_list.length === 0) return;

    const updatedSceneList = script.scenes.map((scene, index) => {
      if (index === view.sceneIndex) {
        const updatedShotList = scene.shot_list.map((shot) => ({
          ...shot,
          expanded: expand,
        }));
        return {
          ...scene,
          shot_list: updatedShotList,
        };
      }
      return scene;
    });

    setView((prevView) => ({
      ...prevView,
      expandShots: expand,
    }));

    setScript((prevScript) => ({
      ...prevScript,
      scenes: updatedSceneList,
    }));
  }

  const expandContractShot = (shot) => {
    shot.expanded = !shot.expanded;
    updateShot(script.scenes[view.sceneIndex].id, shot, 'expanded');
  };

  const handleDragEnd = async (result) => {
    if (!result.destination || script.level === 'viewer') return;
    const { source, destination } = result;
    if (source.index === destination.index) return;

    setSelectedShot(script.scenes[view.sceneIndex].shot_list[source.index].id);
    const updatedShots = Array.from(script.scenes[view.sceneIndex].shot_list);
    const [movedShot] = updatedShots.splice(source.index, 1);
    updatedShots.splice(destination.index, 0, movedShot);

    script.scenes[view.sceneIndex].shot_list = updatedShots;
    updateScriptScene(script.scenes);
    updateShotNumbers(script.scenes[view.sceneIndex]);
  };

  useEffect(() => {
    const setupDictionaries = async () => {
      if (shotDictionaries['frame'].length === 0) {
        let frames = await dictionaryService.fetchUserDictionary('frame', script.user_id);
        shotDictionaries['frame'] = frames;
      }
      if (shotDictionaries['focus'].length === 0) {
        let focus = await dictionaryService.fetchUserDictionary('focus', script.user_id);
        shotDictionaries['focus'] = focus;
      }
      if (shotDictionaries['camera'].length === 0) {
        let camera = await dictionaryService.fetchUserDictionary('camera', script.user_id);
        shotDictionaries['camera'] = camera;
      }
      if (shotDictionaries['movement'].length === 0) {
        let movement = await dictionaryService.fetchUserDictionary('movement', script.user_id);
        shotDictionaries['movement'] = movement;
      }
      if (shotDictionaries['focal'].length === 0) {
        let focal = await dictionaryService.fetchUserDictionary('focal', script.user_id);
        shotDictionaries['focal'] = focal;
      }

      let subjects = await dictionaryService.fetchScriptDictionary('subjects', script.id);
      shotDictionaries['subjects'] = subjects;

      if (shotDictionaries['types'].length === 0) {
        const allShots = await dictionaryService.fetchDictionary('shottype');
        const shots = allShots.map((shot) => shot.name);
        shots.unshift('');
        shotDictionaries['types'] = shots;
      }

      setShotDictionaries(shotDictionaries);
      setLoadComplete(true);
    };

    setupDictionaries();
    expandAll(view.expandShots);
  }, [view.sceneIndex, view.updateDictionary]);

  /*
  useEffect(() => {
    const updateDictionaries = async () => {
      try {
        if (view.updateDictionaries === false) return;
        let subjects = await dictionaryService.fetchScriptDictionary('subjects', script.id);

        setShotDictionaries((prevDictionaries) => ({
          ...prevDictionaries,
          subjects: subjects,
        }));
        setView((prevView) => ({
          ...prevView,
          updateDictionary: {
            ...prevView.updateDictionary,
            subjects: false,
          },
        }));
      } catch (error) {
        console.error('Error updating dictionaries:', error);
      }
    };

    updateDictionaries();
  }, [view.updateDictionary, script.id]);
*/

  useEffect(() => {
    const updateDictionaries = async () => {
      try {
        // Exit if no updates are needed
        if (!Object.values(view.updateDictionary).some((value) => value === true)) return;

        const updatedDictionaries = {};
        const updatedFlags = {};

        for (const key of Object.keys(view.updateDictionary)) {
          if (view.updateDictionary[key] === true) {
            let dictionary = [];
            if (key === 'subjects') {
              dictionary = await dictionaryService.fetchScriptDictionary(key, script.id);
            } else {
              dictionary = await dictionaryService.fetchUserDictionary(key, script.user_id);
            }
            updatedDictionaries[key] = dictionary;
            updatedFlags[key] = false;
          }
        }

        // Update shot dictionaries with the fetched values
        setShotDictionaries((prevDictionaries) => ({
          ...prevDictionaries,
          ...updatedDictionaries,
        }));

        // Update view to set all flags in updateDictionary to false
        setView((prevView) => ({
          ...prevView,
          updateDictionary: {
            ...prevView.updateDictionary,
            ...updatedFlags,
          },
        }));
      } catch (error) {
        console.error('Error updating dictionaries:', error);
      }
    };

    updateDictionaries();
  }, [view.updateDictionary, script.id]);

  return (
    <div className={`shotTable`} style={{ marginLeft: '20px' }}>
      {generalModalDetails && (
        <GeneralModal
          generalModalDetails={generalModalDetails}
          setModalIsOpen={setGeneralModalDetails}
        />
      )}
      {script.scenes[view.sceneIndex] && script.scenes[view.sceneIndex].shot_list ? (
        <DragDropContext onDragEnd={isDraggable ? handleDragEnd : () => {}}>
          <div className="shotList">
            {isMobile ? (
              <h3 className="fullWidthContainer">
                {script.scenes[view.sceneIndex].scene_number ? (
                  <>{script.scenes[view.sceneIndex].scene_number}.</>
                ) : null}
                {script.scenes[view.sceneIndex].header_text}
              </h3>
            ) : null}
            <div>
              <span className="purpleText shotCount">{`${
                script.scenes[view.sceneIndex].shot_list.length
              } Shots`}</span>

              <span className="shotListOptions">
                Expand Shots
                {view.expandShots ? (
                  <Icon icon="ToggleOn" size={35} color="Gray" onClick={() => expandAll(false)} />
                ) : (
                  <Icon icon="ToggleOff" size={35} color="Gray" onClick={() => expandAll(true)} />
                )}
              </span>

              <span className="shotListOptions">
                Include Images
                {view.includeImages ? (
                  <Icon
                    icon="ToggleOn"
                    size={35}
                    color="Gray"
                    onClick={() => includeImages(false)}
                  />
                ) : (
                  <Icon
                    icon="ToggleOff"
                    size={35}
                    color="Gray"
                    onClick={() => includeImages(true)}
                  />
                )}
              </span>
            </div>
            {script.scenes[view.sceneIndex] && script.scenes[view.sceneIndex].generation_error && (
              <div
                className="fullWidthContainer"
                style={{ paddingLeft: '20px', fontStyle: 'italic' }}
              >
                AI could not generate scenes due to{' '}
                {script.scenes[view.sceneIndex].generation_error}
              </div>
            )}
          </div>

          <div
            className={`shotList hiddenScroll ${
              isMobile ? '' : view.viewColumns === 2 ? 'limitHeight' : 'limitHeightSmall'
            }`}
          >
            <Droppable droppableId="shot-list" direction="vertical">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ marginBottom: '80px' }}
                >
                  {script.scenes[view.sceneIndex].shot_list &&
                    script.scenes[view.sceneIndex].shot_list.length > 0 &&
                    loadComplete &&
                    script.scenes[view.sceneIndex].shot_list.map((shot, index) => (
                      <div key={index}>
                        {isMobile ? (
                          <div
                            className="FullWidthFlex"
                            style={{ padding: '15px', paddingBottom: '0px', marginBottom: '-5px' }}
                          >
                            <span
                              className="OnePortionFlex"
                              style={{
                                fontWeight: '600',
                                minWidth: '50px',
                                alignSelf: 'flex-start',
                                paddingTop: '5px',
                              }}
                            >
                              {script.scenes[view.sceneIndex].scene_number ? (
                                <>{script.scenes[view.sceneIndex].scene_number}.</>
                              ) : null}
                              {`${shot.shot_number}`}
                            </span>
                            <span className="OnePortionFlex"></span>
                            <span className="ThreePortionFlex">
                              <ControlBox
                                key={index.toString()}
                                scene={script.scenes[view.sceneIndex]}
                                shot={shot}
                                deleteHandler={deleteHandler}
                                addShot={addShot}
                                expandContractShot={expandContractShot}
                                view={view}
                              />
                            </span>
                          </div>
                        ) : null}
                        <Draggable
                          key={index.toString()}
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={!isDraggable}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() => setSelectedShot(shot.id)}
                            >
                              <Shot
                                shot={shot}
                                isSelected={shot.id === selectedShot}
                                updateShot={updateShot}
                                scene={script.scenes[view.sceneIndex]}
                                dictionaries={shotDictionaries}
                                updateDictionaries={setShotDictionaries}
                                deleteHandler={deleteHandler}
                                addShot={addShot}
                                view={view}
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      ) : script.features && script.features.toLowerCase() !== 'basic' ? (
        <div className="shotList sizeUp">
          <div className="fullWidthContainer noShotlistIconBox">
            <span className="OnePortionFlex">&nbsp;</span>
            <span className="OnePortionFlex">
              <p>
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="dkGrayText circleIcon noShotlistIcon"
                />
              </p>
            </span>
            <span className="OnePortionFlex">&nbsp;</span>
          </div>
          <div className="fullWidthContainer">
            {script.scenes[view.sceneIndex] &&
            (script.scenes[view.sceneIndex].ai_status === 1 ||
              script.scenes[view.sceneIndex].isGeneratingShots) ? (
              <h3 className="centered">Please wait while your shots are generating </h3>
            ) : view.uploadedFile &&
              script.scenes[view.sceneIndex] &&
              !script.scenes[view.sceneIndex].scene_text ? (
              <span className="centered">
                <h3>Shotlist not available</h3>
                Scene does not contain script text.
              </span>
            ) : script.scenes[view.sceneIndex] && script.scenes[view.sceneIndex].scene_text ? (
              <span className="centered">
                <h3>No shotlist yet</h3>
                Click generate to get started
              </span>
            ) : (
              <span className="centered">
                <h3>No shotlist yet</h3>
                Add scene text to the left and click generate to get started
              </span>
            )}
          </div>
          <div className="fullWidthContainer">
            <p className="centered"></p>
          </div>
        </div>
      ) : null}

      {script.features &&
      script.features.toLowerCase() === 'basic' &&
      script.scenes[view.sceneIndex] &&
      script.scenes[view.sceneIndex].ai_status < 1 ? (
        <div
          className="shotList"
          style={{ marginTop: '10px', marginBottom: '60px', textAlign: 'center' }}
        >
          <div style={{ paddingRight: '0px' }}>
            <ProFlag
              type="pro"
              scriptLevel={script ? script.features : 'Basic'}
              scriptId={script.id}
            />
            <span className="label sizeUp" style={{ paddingLeft: '10px' }}>
              You are out of Pro credits <br />
              <br />
            </span>
          </div>
          <div>
            Continue shotlisting manually, <br />
            or upgrade now. <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ShotTable;
