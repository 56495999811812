const headers = (method, body) => {
  let header = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  if (body) {
    header['Content-Type'] = 'application/json';
    return {
      method: method,
      headers: header,
      body: JSON.stringify(body),
    };
  } else {
    return {
      method: method,
      headers: header,
    };
  }
};

const sqlFormattedDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};

const adminSubscriptionService = {
  getAllSubscriptions: async function (parameters) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/subscribe/staff/fetchRecurringSubscriptions`;
      const response = await fetch(url, headers('POST', parameters));
      if (response.ok) {
        const subs = await response.json();
        return subs;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  },

  getAllUserSubscriptions: async function (recurringId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/subscribe/staff/fetchUserSubscriptions/${recurringId}`;
      const response = await fetch(url, headers('GET'));
      if (response.ok) {
        const subs = await response.json();
        return subs;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  },

  getAllPlans: async function () {
    try {
      let url = `${process.env.REACT_APP_API_URL}/subscribe/staff/getAllProductPlans`;
      const response = await fetch(url, headers('GET'));
      if (response.ok) {
        const plans = await response.json();
        return plans;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  },

  getAllCreditUsage: async function (subscriptionId, userId) {
    try {
      let url = '';
      if (subscriptionId) {
        url = `${process.env.REACT_APP_API_URL}/subscribe/staff/getCreditUsageForSub/${subscriptionId}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/subscribe/staff/getCreditUsageForUser/${userId}`;
      }
      const response = await fetch(url, headers('GET'));
      if (response.ok) {
        const creditUsage = await response.json();
        return creditUsage;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  },

  addCredits: async function (sub, credits, endDate) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/subscribe/staff/addCredits`;
      let parameters = {
        userId: sub.user_id,
        subId: sub.id,
        credits: credits,
        endDate: endDate,
      };
      const response = await fetch(url, headers('POST', parameters));
      if (response.ok) {
        let newAllowance = await response.json();
        return newAllowance;
      } else {
        console.error('bad response from add credit service');
        return sub.available_credits;
      }
    } catch (error) {
      console.error(error);
    }
  },

  subtractFromAllowance: async function (user, allowance, credits, comments) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/subscribe//staff/subtractFromAllowance`;
      let parameters = {
        userId: user.id,
        allowance: allowance.id,
        comments: comments,
        subtract: credits,
      };
      const response = await fetch(url, headers('POST', parameters));
      if (response.ok) {
        let updatedAllowance = await response.json();
        return updatedAllowance;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export default adminSubscriptionService;
