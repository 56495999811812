const axios = require('axios');

function params(method, body) {
  let header = {};
  if (body) {
    header = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
    body = JSON.stringify(body);
    return { method: method, headers: header, body: body };
  } else {
    header = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return { method: method, headers: header };
  }
}

const userService = {
  /**
   * Validates a user token by making a request to the server.
   *
   * @returns {Promise<boolean>} A Promise that resolves to `true` if the token is valid, or `false` otherwise.
   * @throws {Error} If there's an error during the validation process.
   */
  validateToken: async function (accessToken) {
    try {
      if (accessToken) {
        localStorage.setItem('token', accessToken);
      }

      let url = `${process.env.REACT_APP_API_URL}/users/validateToken`;
      const response = await fetch(url, params('GET'));
      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('ERROR');
      console.log(error);
      //throw new Error(`Validate Token failed: ${error.message}`);
    }
  },

  /**
   * Validates a staff user token by making a request to the server.
   *
   * @returns {Promise<boolean>} A Promise that resolves to `true` if the token is valid, or `false` otherwise.
   * @throws {Error} If there's an error during the validation process.
   */
  validateStaffToken: async function () {
    try {
      let url = `${process.env.REACT_APP_API_URL}/users/validateStaffToken`;
      const response = await fetch(url, params('GET'));
      if (response.ok) {
        let staffToken = await response.json();
        return staffToken;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  /**
   * Retrieves user data from the backend using the provided authentication token.
   *
   * @param {string} token - The authentication token to be included in the request headers.
   * @returns {Promise<Object|null>} A promise that resolves to a user object if the request is successful,
   * or null if the request fails or encounters an error.
   */
  getUserData: async function (token, count) {
    try {
      count = count || 0;
      let url = `${process.env.REACT_APP_API_URL}/users/fetchUser`;
      const response = await fetch(url, params('GET'));

      if (response.ok) {
        const user = await response.json();
        if (user.id) {
          return user;
        }
      }
      if (count < 3) {
        count++;
        console.log('No user data found, retrying...');
        return this.getUserData(token, count);
      }

      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Retrieves user about us data from the backend
   *
   * @param {string} userId - The ID of the user to pull the aboutUs for.
   * @returns {Promise<Object|null>} A promise that resolves to an about us object if the request is successful,
   * or null if the request fails or encounters an error.
   */
  getUserAboutUs: async function (userId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/users/profile/aboutUs/${userId}`;
      const response = await fetch(url, params('GET'));

      if (response.ok) {
        const user = await response.json();
        return user;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Retrieves the available credits for a specified user from the server.
   * @async
   * @param {string} userId - OPTIONAL: The ID of the user whose available credits are to be retrieved or current user
   * @returns {Promise<number|null>} A promise that resolves with the available credits count if the request is successful, otherwise null.
   */

  getUserAvailableCredits: async function (userId) {
    try {
      let user = userId || '';
      let url = `${process.env.REACT_APP_API_URL}/users/availableCredits/${user}`;
      const response = await fetch(url, params('GET'));

      if (response.ok) {
        const detail = await response.json();
        return detail;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Adds an action log for a user.
   *
   * @param {string} action REQUIRED - The action to be logged.
   * @param {string} ref OPTIONAL - The reference associated with the action.
   * @param {string} refType OPTIONAL - The type of reference (e.g., 'script').
   * @param {string} notes OPTIONAL - Additional notes or details about the action.
   * @param {string} userId OPTIONAL - The unique identifier of the user for whom the action is logged, or defaults to logged in user
   * @returns {Promise<object|null>} A Promise that resolves to the logged user object if the action log was successful; otherwise, it resolves to null.
   * @throws {Error} Throws an error if there is an issue with the network request or server response.
   */
  addActionLog: async function (action, ref, refType, notes, userId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/users/actionLog`;
      let requestBody = {
        action: action,
        notes: notes,
        reference: ref,
        referenceType: refType,
        userId: userId,
      };
      const response = await fetch(url, params('POST', requestBody));

      if (response.ok) {
        const logId = await response.json();
        return logId;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Mark a user action log entry as complete via an API request.
   *
   * @param {number} logId REQUIRED - The unique identifier of the user action log entry to mark as complete.
   * @returns {number|null} - The log ID of the completed entry if successful, or null if there's an issue or failure.
   */
  completeActionLog: async function (logId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/users/actionLog/${logId}`;
      let requestBody = { type: 'complete' };
      const response = await fetch(url, params('PATCH', requestBody));

      if (response.ok) {
        const logId = await response.json();
        return logId;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Update a user action log entry via an API request.
   *
   * @param {number} logId REQUIRED - The unique identifier of the user action log entry to update.
   * @param {string} ref OPTIONAL - The reference associated with the update.
   * @param {string} refType OPTIONAL - The type of reference (e.g., 'task', 'issue').
   * @param {string} notes OPTIONAL - Additional notes or information for the update.
   * @returns {number|null} - The log ID of the updated entry if successful, or null if there's an issue or failure.
   */
  updateActionLog: async function (logId, ref, refType, notes) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/users/actionLog/${logId}`;
      let requestBody = {
        type: 'update',
        reference: ref,
        referenceType: refType,
        notes: notes,
      };
      const response = await fetch(url, params('PATCH', requestBody));

      if (response.ok) {
        const logId = await response.json();
        return logId;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Updates the profile fields of a user.
   *
   * @param {Object} userDetails - An object containing the updated user profile fields.
   * @returns {Promise<Object>} A Promise that resolves with the updated user profile information if the update is successful.
   * @throws {Error} If there is an issue with the API request or if the API responds with an error message.
   */
  updateProfileFields: async function (userDetails) {
    let url = `${process.env.REACT_APP_API_URL}/users/profile`;
    const response = await fetch(url, params('PATCH', userDetails));

    if (response.ok) {
      const user = await response.json();
      return user;
    } else {
      const error = await response.json();
      throw new Error(error.message);
    }
  },
};

export default userService;
